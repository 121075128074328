const CircleQuestion = ({ size = 11, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.5" cy="4.5" r="4.25" stroke="#577CFF" stroke-width="0.5" />
      <path
        d="M4.08995 2.31H4.90895L4.83195 5.642H4.16695L4.08995 2.31ZM4.02695 6.587C4.02695 6.45633 4.07129 6.34667 4.15995 6.258C4.25329 6.16467 4.36762 6.118 4.50295 6.118C4.63362 6.118 4.74562 6.16467 4.83895 6.258C4.93229 6.34667 4.97895 6.45633 4.97895 6.587C4.97895 6.72233 4.93229 6.83667 4.83895 6.93C4.74562 7.02333 4.63362 7.07 4.50295 7.07C4.36762 7.07 4.25329 7.02333 4.15995 6.93C4.07129 6.83667 4.02695 6.72233 4.02695 6.587Z"
        fill="#577CFF"
      />
    </svg>
  );
};

export default CircleQuestion;
