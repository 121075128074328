import styles from './Contaminants.module.css';
import { Contaminant } from 'api/types';
import CircleCheck from '../../Icons/CircleCheck';
import clsx from 'clsx';
import { chunk } from 'utils/array';
import { useRef, useState } from 'preact/hooks';

const Contaminants = ({ contaminants }: { contaminants: Contaminant[] }) => {
  const [minUnitWidth, setMinUnitWidth] = useState(0);
  const checkUnitWidth = (element: HTMLSpanElement) => {
    const { width = 0 } = element?.getBoundingClientRect() ?? {};
    setMinUnitWidth((val) => Math.max(val, width));
  };

  // Chunk contaminants into groups of two
  const rows = chunk(contaminants, 2);

  return (
    <div className={styles.llContaminants}>
      {rows.map((current) => (
        <div className={styles.llContaminantsRow}>
          {current.map(({ name, value }) => (
            <div className={styles.llContaminantsBox}>
              <span className={styles.llContaminantsName}>{name}</span>
              <div className={styles.llContaminantPassed}>
                <CircleCheck />
                Passed
              </div>
              <span style={{ minWidth: minUnitWidth }} className={styles.llContaminantsUnit} ref={checkUnitWidth}>
                {value}
              </span>
            </div>
          ))}
          {current.length === 1 && <div className={styles.llSingleContaminant}></div>}
        </div>
      ))}
    </div>
  );
};

export default Contaminants;
