import * as React from 'react';
import { SVGProps } from 'react';
const LightLabsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={15} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="mask0_926_7317"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={20}
      height={15}
    >
      <path d="M19.6937 0H0V14.1138H19.6937V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_926_7317)">
      <path d="M1.84284 3.15869L3.90793 3.31952L1.94019 5.50626L0.0673828 5.21446L1.84284 3.15869Z" fill="#94ADF0" />
      <path d="M4.14096 3.34082L6.42717 3.51106L4.26264 5.85862L2.19531 5.60326L4.14096 3.34082Z" fill="#94ADF0" />
      <path
        d="M6.65927 3.61476L11.4727 9.77758L18.5773 0.16748L19.6941 2.07406L11.4534 14.0915L4.42578 6.07987L6.65927 3.61476Z"
        fill="#94ADF0"
      />
      <path d="M2.20508 5.85596L4.11072 6.10372L11.1752 14.1135L9.10729 14.0861L2.20508 5.85596Z" fill="#94ADF0" />
      <path d="M1.89908 5.80415L8.76158 14.0811L6.86772 14.0865L0 5.48486L1.89908 5.80415Z" fill="#94ADF0" />
      <path d="M13.46 0L15.6058 0.0198617L9.90034 7.39096L8.85645 5.98541L13.46 0Z" fill="#94ADF0" />
      <path d="M15.9033 0H18.4066L11.4529 9.51916L10.0225 7.53179L15.9033 0Z" fill="#94ADF0" />
    </g>
  </svg>
);
export default LightLabsIcon;
