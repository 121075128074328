import { ProductConfig } from 'api/types';
import { camelize } from 'utils/formatters';

const ATTRIBUTE_PREFIX = 'data-';

const parseValue = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const getDataAttributes = (element: HTMLScriptElement) => {
  return Array.from(element?.attributes ?? []).reduce((acc, { name, value }) => {
    if (name.startsWith(ATTRIBUTE_PREFIX)) {
      const camelName = camelize(name.slice(ATTRIBUTE_PREFIX.length));
      return { ...acc, [camelName]: parseValue(value) };
    }
    return acc;
  }, {} as ProductConfig);
};

// The latest script to load should be this one, but check that the name starts
// with `ll-` just in case. Otherwise fallback to looking for a specific name.
// This will allow us to use this same config for different widgets (pdp, lookup, etc.)
// without creating individual config objects.
const scripts = document.getElementsByTagName('script');
const latestLoadedScript = scripts[scripts.length - 1];
export const scriptElement = /\/ll-.*\.js$/.test(latestLoadedScript?.src)
  ? latestLoadedScript
  : Array.from(document.getElementsByTagName('script')).find(({ src }) => {
      return src.includes('widget.tsx') || src.includes('ll-product-widget');
    });

export const updateConfig = (updatedValues: Partial<ProductConfig> = {}) => {
  const nonNulls = Object.keys(updatedValues)
    .filter((key) => updatedValues[key] !== null)
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: updatedValues[key],
      }),
      {},
    );
  Object.assign(config, nonNulls);
};

/**
 * Return the script config including all `data-` attributes off
 * the script tag of the bundle
 */
export const parseConfig = () => {
  const dataAttributes = getDataAttributes(scriptElement);

  return {
    mountElementId: 'll-product-widget',
    version: __APP_VERSION__,
    apiUrl: 'https://app.lightlabs.com',
    layoutVersion: 1,
    hideActiveIngredients: false,
    hideContaminants: false,
    includeFdaLink: true,
    lotLabelText: 'Lot',
    ...dataAttributes,
  };
};

export const config = parseConfig();
