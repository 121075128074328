import styles from './Active.module.css';
import { Bar } from 'api/types';
import clsx from 'clsx';

const Active = ({ bars }: { bars: Bar[] }) => {
  return (
    <table className={styles.llActive}>
      <tbody>
        {bars.map(({ name, actual, claim, units }) => (
          <tr className={styles.llActiveBar}>
            <td>
              <div className={clsx(styles.llActiveContent, styles.llActiveName)}>{name}</div>
            </td>
            <td>
              <div className={styles.llActiveContent}>
                <span>Claim:</span>
                <span className={styles.llActiveValue}>
                  {claim}
                  {units}
                </span>
              </div>
            </td>
            <td>
              <div className={clsx(styles.llActiveContent, styles.llActiveBox)}>
                <span>Actual:</span>
                <span className={styles.llActiveValue}>
                  {actual}
                  {units}
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Active;
