import { useEffect, useState } from 'preact/hooks';
import { config, getDataAttributes, parseConfig, scriptElement, updateConfig } from 'utils/config';
import logger from 'utils/logger';
import { ProductConfig } from 'api/types';
import { getQueryParams } from 'utils/location';

type UseConfig = {
  config: ProductConfig;
  applyConfig: (changes: Partial<ProductConfig>) => void;
};

const useConfig = (): UseConfig => {
  const [stateConfig, setStateConfig] = useState<ProductConfig>(config);
  const queryParams = getQueryParams();
  const { variant } = queryParams;

  const applyConfig = (changes: Partial<ProductConfig>) => {
    // Update the global config
    updateConfig(changes);
    logger.info('Updated Script Config', config);

    // Set a copy of the global config in state to force a render
    setStateConfig({ ...config });
  };

  // Watch all attributes of the script tag and apply changes to the global config
  useEffect(() => {
    const observer = new MutationObserver((mutation) => {
      const attributeChanged = mutation.some(({ type }) => type === 'attributes');
      if (attributeChanged) {
        // Pull all updated data attributes
        const dataAttributes = getDataAttributes(scriptElement);
        applyConfig(dataAttributes);
      }
    });
    if (scriptElement) {
      observer.observe(scriptElement, { attributes: true });
    }
    return () => observer.disconnect();
  }, []);

  // If the variant query param is updated, apply it to the config
  useEffect(() => {
    // Don't apply the query param variant if already set by a data attribute
    if (variant && !stateConfig.variant) {
      applyConfig({ variant });
    }
  }, [variant]);

  return {
    config: stateConfig,
    applyConfig,
  };
};

export default useConfig;
