import styles from './Collapsible.module.css';
import DropdownArrow from 'components/Icons/DropdownArrow';
import { useEffect, useRef, useState } from 'preact/hooks';
import clsx from 'clsx';
import { ComponentChildren } from 'preact';

const EXPAND_BUFFER = 50;

export type CollapsibleProps = {
  label?: ComponentChildren;
  children?: ComponentChildren;
  className?: string;
  expandIcon?: ComponentChildren;
  collapseIcon?: ComponentChildren;
  animateIcon?: boolean;
};

const Collapsible = ({
  children,
  label,
  className,
  expandIcon = <DropdownArrow />,
  collapseIcon = expandIcon,
  animateIcon = true,
  ...props
}: CollapsibleProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(500);
  const childrenRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (childrenRef.current) {
      const { height } = childrenRef.current.getBoundingClientRect();
      setMaxHeight(height + EXPAND_BUFFER);
    }
  }, [childrenRef, children]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div {...props} className={clsx(styles.collapsible, className)}>
      <div className={styles.label} onClick={handleClick}>
        {label}
        <div className={clsx(styles.openIcon, { [styles.openIconRotate]: isExpanded && animateIcon })}>
          {isExpanded ? collapseIcon : expandIcon}
        </div>
      </div>
      <div
        className={clsx(styles.expander, { [styles.expanded]: isExpanded })}
        style={{ maxHeight: isExpanded ? maxHeight : 0 }}
      >
        <div ref={childrenRef}>{children}</div>
      </div>
    </div>
  );
};

export default Collapsible;
