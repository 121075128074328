const CircleQuestion = ({ size = 14, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <ellipse cx={8.44164} cy={8} rx={8.3352} ry={8} />
      <path
        d="M7.36328 9.75V9.70312C7.36849 9.20573 7.42057 8.8099 7.51953 8.51562C7.61849 8.22135 7.75911 7.98307 7.94141 7.80078C8.1237 7.61849 8.34245 7.45052 8.59766 7.29687C8.7513 7.20312 8.88932 7.09245 9.01172 6.96484C9.13411 6.83464 9.23047 6.6849 9.30078 6.51562C9.3737 6.34635 9.41016 6.15885 9.41016 5.95312C9.41016 5.69792 9.35026 5.47656 9.23047 5.28906C9.11068 5.10156 8.95052 4.95703 8.75 4.85547C8.54948 4.75391 8.32682 4.70312 8.08203 4.70312C7.86849 4.70312 7.66276 4.7474 7.46484 4.83594C7.26693 4.92448 7.10156 5.0638 6.96875 5.25391C6.83594 5.44401 6.75911 5.69271 6.73828 6H5.75391C5.77474 5.55729 5.88932 5.17839 6.09766 4.86328C6.30859 4.54818 6.58594 4.30729 6.92969 4.14062C7.27604 3.97396 7.66016 3.89062 8.08203 3.89062C8.54036 3.89062 8.9388 3.98177 9.27734 4.16406C9.61849 4.34635 9.88151 4.59635 10.0664 4.91406C10.2539 5.23177 10.3477 5.59375 10.3477 6C10.3477 6.28646 10.3034 6.54557 10.2148 6.77734C10.1289 7.00911 10.0039 7.21615 9.83984 7.39844C9.67839 7.58073 9.48307 7.74219 9.25391 7.88281C9.02474 8.02604 8.84115 8.17708 8.70313 8.33594C8.5651 8.49219 8.46484 8.67839 8.40234 8.89453C8.33984 9.11068 8.30599 9.38021 8.30078 9.70312V9.75H7.36328ZM7.86328 12.0625C7.67057 12.0625 7.50521 11.9935 7.36719 11.8555C7.22917 11.7174 7.16016 11.5521 7.16016 11.3594C7.16016 11.1667 7.22917 11.0013 7.36719 10.8633C7.50521 10.7253 7.67057 10.6562 7.86328 10.6562C8.05599 10.6562 8.22135 10.7253 8.35938 10.8633C8.4974 11.0013 8.56641 11.1667 8.56641 11.3594C8.56641 11.487 8.53385 11.6042 8.46875 11.7109C8.40625 11.8177 8.32161 11.9036 8.21484 11.9687C8.11068 12.0313 7.99349 12.0625 7.86328 12.0625Z"
        fill="white"
      />
    </svg>
  );
};

export default CircleQuestion;
