import { config } from './config';

const LOG_PREFIX = `light-labs ${config.version} |`;
type Level = 'log' | 'debug' | 'info' | 'warn' | 'error';

interface Logger {
  log(...args: any[]): void;

  debug(...args: any[]): void;

  info(...args: any[]): void;

  warn(...args: any[]): void;

  error(...args: any[]): void;
}

const consoleLog = (level: Level, ...args: any) => {
  if (config.debug) {
    console?.[level](LOG_PREFIX, ...args);
  }
};

const logger = (['log', 'debug', 'info', 'warn', 'error'] as Level[]).reduce((acc, level) => {
  return {
    ...acc,
    [level]: (...args) => consoleLog(level, ...args),
  };
}, {} as Logger);

export default logger;
