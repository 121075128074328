import * as React from 'react';
import { SVGProps } from 'react';
const CirclePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={9} cy={9} r={9} fill="#3A3A3A" />
    <path
      d="M9.47026 8.968H12.3403V9.78H9.47026V12.706H8.54626V9.78H5.67626V8.968H8.54626V6.042H9.47026V8.968Z"
      fill="white"
    />
  </svg>
);
export default CirclePlus;
