import { Contaminant } from 'api/types';
import CircleCheck from '../../Icons/CircleCheck';
import CircleQuestion from '../../Icons/CircleQuestion';
import ToolTip from '../../ToolTip/ToolTip';
import styles from './Contaminants.module.css';

const Contaminants = ({ contaminants }: { contaminants: Contaminant[] }) => {
  return (
    <div className={styles.llContaminants}>
      <div className={styles.llContaminantsBox}>
        This product <span className={styles.llEmphasis}>has passed</span> strict quality, safety, and regulatory
        standards.
      </div>
      <div className={styles.llContaminantsTesting}>
        Rigorously tested to ensure product purity and compliance
        <ToolTip
          message="Units are parts per billion (ppb) or micrograms (μg). Testing standards are from California's Prop 65."
          className={styles.llTestingIcon}
        >
          <CircleQuestion />
        </ToolTip>
      </div>
      <div className={styles.llContaminantsList}>
        {contaminants.map(({ name, value }) => (
          <div className={styles.llContaminantsBox}>
            <span className={styles.llContaminantsName}>
              <CircleCheck />
              {name}
            </span>
            <span className={styles.llContaminantsUnit}>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contaminants;
