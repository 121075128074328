import styles from './ContaminantGroups.module.css';
import { ContaminantGroup } from 'api/types';
import ContaminantGroupItem from 'components/v4/ContaminantGroupItem/ContaminantGroupItem';

const ContaminantGroups = ({ contaminantGroups }: { contaminantGroups: ContaminantGroup[] }) => {
  return (
    <div className={styles.contaminantGroups}>
      <div className={styles.title}>Contaminants</div>
      {contaminantGroups.map((group) => (
        <ContaminantGroupItem contaminantGroup={group} />
      ))}
    </div>
  );
};

export default ContaminantGroups;
