import { ProductAPI } from 'api/product';
import { Product, Theme } from 'api/types';
import useConfig from 'hooks/useConfig';
import { useEffect, useState } from 'preact/hooks';
import logger from '../utils/logger';

export type UseProductType = {
  product?: Product;
  theme?: Theme;
};

export const useProduct = (): UseProductType => {
  const { location } = window;
  const [products, setProducts] = useState<Product[] | undefined>();
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const [theme, setTheme] = useState<Theme | undefined>();
  const { config, applyConfig } = useConfig();
  const { variant, unstableActiveIngredientsAllowlist, unstableContaminantsAllowlist } = config;

  // Fetch all product data for the company on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Don't make a request if we can't find the company ID
        const { companyId } = config;
        if (companyId) {
          const { products, config: apiConfig } = await ProductAPI.getProduct(companyId);

          logger.log('Products:', products);
          setProducts(products);

          // Override config with values returned from the API
          applyConfig(apiConfig);
          logger.log('API Config', config);

          setTheme(apiConfig.theme);
        } else {
          logger.log('Failed to find company ID');
        }
      } catch (error) {
        logger.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (products) {
      // Lookup specific product info
      const parts = location.href.split('/');
      const matchedProduct = products.find(
        ({ slugsToMatch }) => !!parts.find((part) => !!slugsToMatch.find((slug) => part.includes(slug))),
      );

      if (!matchedProduct) {
        logger.log('Failed to match product slugs:', parts);
        return;
      } else {
        logger.log('Matched Product:', matchedProduct);
      }

      if (unstableActiveIngredientsAllowlist) {
        matchedProduct.bigBars = matchedProduct.bigBars.filter((bar) => {
          return unstableActiveIngredientsAllowlist.includes(bar.id);
        });
      }

      if (unstableContaminantsAllowlist) {
        matchedProduct.contaminants = matchedProduct.contaminants.filter((contaminant) => {
          return unstableContaminantsAllowlist.includes(contaminant.id);
        });
      }

      setSelectedProduct(matchedProduct);
    }
  }, [products, variant]);

  return {
    product: selectedProduct,
    theme,
  };
};
