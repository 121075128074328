export const cssVariableToLabel = (key: string) => {
  const parts = key.replace(/^--ll/, '').split('-');
  return parts.map((part) => part).join(' ');
};

export const isObject = (value: any): boolean => {
  return !Array.isArray(value) && value !== null && typeof value === 'object';
};

export const camelize = (value: string): string => {
  return value
    .split(/[-_]/)
    .map((part, index) => (index ? part.charAt(0).toUpperCase() + part.slice(1) : part))
    .join('');
};

export const camelizeKeys = (value: object | object[]) => {
  if (Array.isArray(value)) {
    return value.map((obj) => camelizeKeys(obj));
  }

  return Object.keys(value).reduce((acc, key) => {
    const propertyValue = value[key];
    return {
      ...acc,
      [camelize(key)]:
        propertyValue !== null && typeof propertyValue === 'object' ? camelizeKeys(propertyValue) : propertyValue,
    };
  }, {});
};

export const capitalize = (value: string): string => {
  return value
    .split(/[-_ ]/)
    .map((part, index) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');
};
