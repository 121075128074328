import styles from './Widget.module.css';
import { WidgetProps } from 'components/types';
import CircleExclamation from '../../Icons/CircleExclamation';
import ToolTip from '../../ToolTip/ToolTip';
import Active from '../Active/Active';
import Contaminants from '../Contaminants/Contaminants';
import LiveTestingIndicator from '../LiveTestingIndicator/LiveTestingIndicator';
import variables from '../../../theme/variables.module.css';
import clsx from 'clsx';

const Widget = ({ product, style, hideActiveIngredients, hideContaminants, ...props }: WidgetProps) => {
  const { bigBars, contaminants, lastTestDate } = product;

  return (
    <div className={clsx(styles.llWidgetV2, variables.v2)} style={style} {...props}>
      <div className={styles.llWidgetHeader}>
        Test Results Certified by 3rd Party Lab <LiveTestingIndicator lastTestDate={lastTestDate} />
      </div>
      <div className={styles.llWidgetDescription}>
        This product <span className={styles.llSafeLabel}>has passed</span> strict quality, safety, and regulatory
        standards.
      </div>
      {!hideActiveIngredients && !!bigBars?.length && <Active bars={bigBars} />}

      <div className={styles.llTestingCompliance}>
        Rigorously tested to ensure product purity and compliance
        <ToolTip
          message="Units are parts per billion (ppb) or micrograms (μg). Testing standards are from California's Prop 65."
          className={styles.llTestingIcon}
        >
          <CircleExclamation />
        </ToolTip>
      </div>
      {!hideContaminants && !!contaminants?.length && <Contaminants contaminants={contaminants} />}
    </div>
  );
};

export default Widget;
