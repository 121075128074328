import {Bar} from "api/types";
import Active from "components/v4/Active/Active";
import styles from "./ActiveIngredients.module.css";

type ActiveIngredientsProps = {
  bigBars: Bar[];
}

const ActiveIngredients = ({bigBars}: ActiveIngredientsProps) => {

  return (
    <div className={styles.activeIngredients}>
      <div className={styles.title}>Active Ingredients</div>
      {bigBars.map((bigBar) => <Active bar={bigBar}/>)}
    </div>
  )

}

export default ActiveIngredients
