import styles from './BoldSubstring.module.css';

type BoldSubstringProps = {
  content: string;
  boldStrings: string[];
};
const BoldSubstring = ({ content, boldStrings }: BoldSubstringProps) => {
  const updated = content.replace(new RegExp(`(${boldStrings.join('|')})`, 'gi'), `<b>$1</b>`);
  return <span className={styles.boldSubstring} dangerouslySetInnerHTML={{ __html: updated }}></span>;
};

export default BoldSubstring;
