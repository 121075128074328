import { WidgetProps } from 'components/types';
import Contaminants from '../Contaminants/Contaminants';
import Footer from '../Footer/Footer';
import IngredientsIncluded from '../IngredentsIncluded/IngredientsIncluded';
import styles from './Widget.module.css';
import variables from '../../../theme/variables.module.css';
import clsx from 'clsx';

const Widget = ({ product, style, hideActiveIngredients, hideContaminants, ...props }: WidgetProps) => {
  const { bigBars, contaminants } = product;

  return (
    <div className={clsx(styles.llWidgetV1, variables.v1)} style={style} {...props}>
      <div className={styles.llWidgetHeader}>Test Results Certified by 3rd Party Lab</div>
      <div className={styles.llWidgetDescription}>
        Products are tested by an ISO 17025 certified lab to verify active ingredients and product purity
      </div>
      {!hideContaminants && !!contaminants?.length && <Contaminants contaminants={contaminants} />}
      {!hideActiveIngredients && !!bigBars?.length && <IngredientsIncluded bars={bigBars} />}
      <Footer product={product} />
    </div>
  );
};

export default Widget;
