export const parseDate = (value: string): number | null => {
  const date = Date.parse(value);
  return isNaN(date) ? null : date;
};

export const formatDate = (value: string | number, month: 'long' | 'short' = 'long'): string | null => {
  const date = new Date(value);
  return date instanceof Date && date.toString() !== 'Invalid Date'
    ? date.toLocaleDateString('default', {
        month,
        year: 'numeric',
        day: 'numeric',
      })
    : null;
};
