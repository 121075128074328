import styles from './DetailCollapsible.module.css';
import Collapsible, { CollapsibleProps } from 'components/Collapsible/Collapsible';
import CircleMinus from 'components/Icons/CircleMinus';
import CirclePlus from 'components/Icons/CirclePlus';

const DetailCollapsible = ({ children, ...props }: CollapsibleProps) => {
  return (
    <Collapsible
      {...props}
      className={styles.detailCollapsible}
      expandIcon={<CirclePlus />}
      collapseIcon={<CircleMinus />}
      animateIcon={false}
    >
      {children}
    </Collapsible>
  );
};

export default DetailCollapsible;
