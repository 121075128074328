import styles from './Footer.module.css';
import { Product } from 'api/types';
import ToolTip from '../../ToolTip/ToolTip';
import { formatDate, parseDate } from 'utils/dates';

const MAX_LIVE_TESTING_DAYS = 90;

const Footer = ({ product }: { product: Product }) => {
  const { lastTestDate } = product;
  const date = parseDate(lastTestDate);
  const displayDate = date && date > Date.now() - MAX_LIVE_TESTING_DAYS * 24 * 60 * 60 * 1000 ? formatDate(date) : null;

  if (!displayDate) {
    return null;
  }

  return (
    <div className={styles.llFooter}>
      <ToolTip message={displayDate}>
        <div className={styles.llTesting}>
          <div className={styles.llTestingIcon} />
          Live Testing
        </div>
      </ToolTip>
    </div>
  );
};

export default Footer;
