import styles from './SideBar.module.css';
import clsx from 'clsx';
import { ComponentChildren } from 'preact';

export type SideBarProps = {
  children?: ComponentChildren;
  className?: string;
  isOpen?: boolean;
  onClose?: () => void;
};

const SideBar = (props: SideBarProps) => {
  const { className, children, isOpen, onClose } = props;

  return (
    <div className={clsx(styles.sidebar, className, { [styles.open]: isOpen })}>
      {isOpen && <div className={styles.backdrop} onClick={onClose} />}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default SideBar;
