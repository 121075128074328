import { LotResponse, Product, ProductResponse, SkuResponse } from './types';
import { request } from './request';
import { config } from 'utils/config';

export const getExampleProductData = () =>
  ({
    header: 'Test Results Certified by 3rd Party Lab',
    description: 'Every batch is tested by an ISO 17025 certified lab to verify active ingredients and product purity',
    contaminants: [
      { name: 'Lead', value: '0.23 ppb' },
      { name: 'Arsenic', value: '<0.0000008 ppb' },
      { name: 'Mercury', value: '0.12 ppb' },
      { name: 'Cadmium', value: '<0.08 ppb' },
    ],
    bigBars: [
      { name: 'Gelatin', claim: 10.1, actual: 10.2, units: 'g' },
      { name: 'Creatine', claim: 4.5, actual: 4.5, units: 'g' },
    ],
    lastTestDate: new Date().toISOString(),
    skus: [
      {
        id: '1',
        shopifyVariantId: '1',
        name: 'Sku 1',
        contaminants: [
          { name: 'Mercury', value: '0.12 ppb' },
          { name: 'Cadmium', value: '<0.08 ppb' },
        ],
        bigBars: [
          { name: 'Gelatin', claim: 10.1, actual: 10.2, units: 'g', verified: true },
          { name: 'People', claim: 10, actual: 9, units: 'g', verified: true },
        ],
        contaminantGroups: [
          {
            name: 'Heavy Metals',
            value: '<0.06 ppb',
            passed: true,
            description:
              'Naturally occurring elements that can be harmful at high concentrations, tested against stringent European Union Regulations.',
            successRate: '95%',
            contaminants: [
              { name: 'Mercury', value: '0.12 ppb' },
              { name: 'Cadmium', value: '<0.08 ppb' },
            ],
          },
          {
            name: 'Pork',
            value: '<0.08 ppb',
            passed: false,
            description: 'What do you mean what is this?',
            contaminants: [
              { name: 'Bacon', value: '0.22 ppb' },
              { name: 'Ham', value: '<0.18 ppb' },
              { name: 'Sausage', value: '<0.18 ppb' },
            ],
          },
        ],
      },
      {
        id: '2',
        shopifyVariantId: '2',
        name: 'Sku 2',
        contaminants: [
          { name: 'Mercury', value: '0.12 ppb' },
          { name: 'Cadmium', value: '<0.08 ppb' },
          { name: 'Cadmium', value: '<0.08 ppb' },
        ],
        bigBars: [
          { name: 'Gelatin', claim: 10.1, actual: 10.2, units: 'g', verified: false },
          { name: 'Power', claim: 10.1, actual: 10.2, units: 'g', verified: true },
        ],
      },
    ],
  }) as Product;

export const ProductAPI = {
  getProduct: async (companyId: string): Promise<ProductResponse> => {
    return request({ url: `${config.apiUrl}/api/companies/${companyId}/embed_configurations` });
  },

  getSkus: async (companyId: string): Promise<SkuResponse> => {
    return request({ url: `${config.apiUrl}/api/companies/${companyId}/skus` });
  },

  getLots: async (companyId: string, skuId: string): Promise<LotResponse> => {
    return request({
      url: `${config.apiUrl}/api/companies/${companyId}/skus/${skuId}/lots`,
    });
  },
};
