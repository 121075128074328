import { Bar } from 'api/types';
import styles from './IngredientBar.module.css';
import clsx from 'clsx';

const IngredientBar = ({ bar }: { bar: Bar }) => {
  const { claim, actual } = bar;
  const isVerified = claim === actual;
  const isMinimum = actual >= claim;
  const leftLabel = isVerified ? 'Verified Claim' : isMinimum ? 'Claim' : 'Actual';
  const leftValue = isMinimum ? claim : actual;
  const rightValue = isMinimum ? actual : claim;
  const rightLabel = isMinimum ? 'Actual' : 'Claim';
  const ratio = (1 - leftValue / rightValue) * 100;

  return (
    <div className={styles.llIngredientBar}>
      <div className={styles.llIngredientBarName}>{bar.name}</div>
      <div
        className={clsx(styles.llIngredientBarSegments, {
          [styles.llIngredientInsufficient]: !isMinimum,
        })}
      >
        <div className={styles.llIngredientBarSegmentLeft} style={{ flexGrow: `${100 - ratio}` }}>
          <div className={styles.llIngredientBarSegmentLabel}>{leftLabel}</div>
          <div className={styles.llIngredientBarContent}>
            {leftValue}
            {bar.units}
          </div>
        </div>

        {!isVerified && (
          <div className={styles.llIngredientBarSegmentRight} style={{ flexGrow: `${ratio}` }}>
            <div className={styles.llIngredientBarSegmentLabel}>{rightLabel}</div>
            <div className={styles.llIngredientBarContent}>
              {rightValue}
              {bar.units}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IngredientBar;
