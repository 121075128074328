import styles from './IngredientsIncluded.module.css';
import { Bar } from 'api/types';
import IngredientBar from '../IngredientBar/IngredientBar';

const IngredientsIncluded = ({ bars }: { bars: Bar[] }) => {
  return (
    <div className={styles.llIngredientsIncluded}>
      {bars.map((bar) => (
        <IngredientBar bar={bar} />
      ))}
    </div>
  );
};

export default IngredientsIncluded;
