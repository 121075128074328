import logger from 'utils/logger';

export const request = async ({ method = 'get', url }: { method?: 'get' | 'post'; url: string }) => {
  const result = await fetch(url, { method });
  if (result.ok) {
    return await result.json();
  }
  logger.error('Failed calling making request', { method, url, result });
  throw new Error('Request failed');
};
