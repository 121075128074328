import styles from '../DetailCollapsible/DetailCollapsible.module.css';
import { Bar } from 'api/types';
import clsx from 'clsx';
import { formatDate } from 'utils/dates';
import DetailCollapsible from 'components/v4/DetailCollapsible/DetailCollapsible';

const DetailActive = ({ bar, lastTestDate }: { bar: Bar; lastTestDate?: string }) => {
  const { name, verified, claim, units } = bar;
  return (
    <DetailCollapsible
      label={
        <div className={styles.labelContent}>
          <div className={styles.labelItem}>
            <div className={styles.label}>Ingredient</div>
            <div className={styles.labelValue}>{name}</div>
          </div>
          <div className={clsx(styles.labelItem, styles.status)}>
            <div className={styles.label}>Status</div>
            <div className={styles.labelValue}>
              {verified ? <div className={styles.pass}>Pass</div> : <div className={styles.fail}>Not Passed</div>}
            </div>
          </div>
          <div className={styles.labelItem}>
            <div className={styles.label}>Last Tested</div>
            <div className={styles.labelValue}>{formatDate(lastTestDate, 'short')}</div>
          </div>
        </div>
      }
    >
      <div className={styles.content}>
        <div className={styles.labelItem}>
          <div className={styles.label}>Claim</div>
          <div className={styles.labelValue}>
            {claim} {units}
          </div>
        </div>

        <div className={styles.labelItem}>
          <div className={styles.label}>How is this product verified?</div>
          <div>
            We verified this product by measuring the ingredient amount against the{' '}
            <span className={styles.emphasis}> FDA's allowable variance</span>. This ensures that the product is labeled
            with complete accuracy.
          </div>
        </div>
      </div>
    </DetailCollapsible>
  );
};

export default DetailActive;
